import React, { useEffect, useState } from "react";
import { ChartModel, DefaultChartModel, api } from "../../../api/twelve";
import RadarChart from "./RadarChart";
import DistributionChart from "./DistributionChart";
import { FullPitchChart } from "./FullPitchChart";
import { ContainerProps, Typography, useTheme } from "@mui/material";
import StringHelper from "../../../shared/helpers/string.helper";
import { ChartType } from "../../../models";
import { ChartLoadingFrame } from "./common/ChartLoadingFrame";

interface Props extends ContainerProps {
  variant: ChartType;
  url?: string;
  json_body?: Record<string, any>;
  size?: "lg" | "sm";
  useLoadingFrame?: boolean;
  loadingFrameVariant?: "default" | "shimmer";
}

export function Chart(props: Props) {
  const {
    variant = "radar",
    url,
    json_body = {},
    size = "lg",
    useLoadingFrame = true,
    loadingFrameVariant = "default",
  } = props;
  const [chart, setChart] = useState<ChartModel>(DefaultChartModel);
  const theme = useTheme();

  useEffect(() => {
    const fn = async () => {
      if (!url) return;

      const chartData = (await api.getChart(
        url,
        json_body,
        "json",
      )) as ChartModel;
      setChart(chartData);
    };
    fn();
  }, [json_body, url]);

  const renderChart = () => {
    if (chart.loading) {
      if (useLoadingFrame) {
        return (
          <ChartLoadingFrame
            size={size}
            sx={{ ...props.sx }}
            variant={loadingFrameVariant}
          />
        );
      }

      return <></>;
    }

    switch (variant) {
      case "radar":
        return (
          <RadarChart
            sx={props.sx}
            size={size}
            title={chart.title}
            subTitle={chart.subtitle}
            bottomTitle={`Compared to other ${StringHelper.normalizeKey(
              chart.player_info?.position,
            )}s`}
            value_range={chart.data.value_range}
            axies={chart.data.kpi_names}
            series={chart.data.plot_values.map(
              (data: Array<number>, index: number) => {
                return {
                  name: chart.data.target_labels[index],
                  color: theme.palette.customColors.dataPoints[index],
                  hover_strings: chart.data.hover_strings[index]?.map((s) =>
                    s.replaceAll("\n", "<br/>"),
                  ),
                  data,
                };
              },
            )}
          />
        );
      case "distribution":
        return (
          <DistributionChart
            {...props}
            size={size}
            title={chart.title}
            subTitle={chart.subtitle}
            range={chart.data.value_range}
            metrics={chart.data.kpis}
            series={[
              {
                name: `Other ${StringHelper.normalizeKey(
                  chart.player_info?.position,
                )}s`,
                values: chart.data.plot_values.filter(
                  (_, i) => i !== chart.data.focal_target_indices[0],
                ),
                hoverStrings: chart.data.hover_strings.filter(
                  (_, i) => i !== chart.data.focal_target_indices[0],
                ),
                marker: {
                  variant: "line",
                  color: theme.palette.customColors.dataPoints[1],
                },
              },
              {
                name: chart.player_info?.name,
                values: [
                  chart.data.plot_values[chart.data.focal_target_indices[0]],
                ],
                hoverStrings: [
                  chart.data.hover_strings[chart.data.focal_target_indices[0]],
                ],
                marker: {
                  variant: "circle",
                  color: theme.palette.customColors.dataPoints[0],
                },
              },
            ]}
          />
        );
      case "pitch":
      case "fullpitch": {
        return (
          <FullPitchChart
            {...props}
            title={chart.title}
            subTitle={chart.subtitle}
            labels={["Defensive", "Offensive"]}
            footerText={`Compared to other ${StringHelper.normalizeKey(
              chart.player_info?.position,
            )}s`}
            data={chart.data.zones}
          />
        );
      }
      default:
        return (
          <Typography variant="body1" sx={{ color: theme.palette.error.main }}>
            Not supported variant
          </Typography>
        );
    }
  };

  return <>{renderChart()}</>;
}
