import React, { useEffect, useRef, useState } from "react";
import { api } from "../../../../api/twelve";
import { useAuth0 } from "@auth0/auth0-react";
import useDebounce from "../../../../shared/hooks/useDebounce";
import ResponsiveDialog from "../../../../shared/components/ResponsiveDialog";
import {
  NoSearchResults,
  SearchBox,
  SearchBoxContainer,
  SearchDefaultText,
  SearchInput,
  SearchList,
  SearchListItemButton,
  SearchListItemTextMain,
  SearchListItemTextSecondary,
  SearchProgress,
} from "./SearchLayoutComponents";
import { SearchPlayerInfo } from "../../../../models";

interface SearchPlayerProps {
  open: boolean;
  onClose: () => void;
  onSelect: (player: SearchPlayerInfo) => void;
}

const SearchPlayerModal: React.FC<SearchPlayerProps> = ({
  open,
  onClose,
  onSelect,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [players, setPlayers] = useState<SearchPlayerInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const debouncedSearchTerm = useDebounce(searchQuery, 600);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    setSearchQuery("");
    setPlayers([]);
  }, [open]);

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const search = async () => {
      setLoading(true);
      try {
        const result = await api.searchPlayers(
          await getAccessTokenSilently(),
          debouncedSearchTerm,
          controller.signal,
        );
        setPlayers(result);
      } catch {
      } finally {
        setLoading(false);
      }
    };

    if (debouncedSearchTerm?.length < 3) {
      if (!debouncedSearchTerm.length) {
        setPlayers([]);
      }
      return;
    }

    search();

    return () => {
      controller.abort();
    };
  }, [getAccessTokenSilently, debouncedSearchTerm]);

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      position="top"
    >
      <SearchBoxContainer>
        <SearchInput
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
        <SearchBox>
          {loading ? (
            <SearchProgress />
          ) : debouncedSearchTerm.length ? (
            players.length > 0 ? (
              <SearchList dense className="scrollable">
                {players.map((player, index) => (
                  <SearchListItemButton
                    key={`player-${index}`}
                    onClick={() => {
                      onSelect(player);
                      onClose();
                    }}
                  >
                    <SearchListItemTextSecondary secondary={player.team} />
                    <SearchListItemTextMain primary={player.name} />
                  </SearchListItemButton>
                ))}
              </SearchList>
            ) : (
              <NoSearchResults text="No players found" />
            )
          ) : (
            <SearchDefaultText text="Find players by their first name and/or last name." />
          )}
        </SearchBox>
      </SearchBoxContainer>
    </ResponsiveDialog>
  );
};

export default SearchPlayerModal;
