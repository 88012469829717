import { useSearchParams } from "react-router-dom";
import { Chart } from "../shared/components/charts/Chart";
import ChartHelper from "../shared/helpers/chart.helper";
import React, { useEffect, useState } from "react";
import { PaletteModeContext } from "../themes/theme";
import { useTheme } from "@mui/material";

export default function ChartPage() {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const paletteMode = React.useContext(PaletteModeContext);
  const [plots, setPlots] = useState([]);
  paletteMode.setMode("dark");

  document.body.style.backgroundColor =
    theme.palette.customColors.report.background;

  useEffect(() => {
    const data = searchParams.get("q");
    if (data) {
      try {
        setPlots(JSON.parse(data));
      } catch {
        setPlots([]);
      }
    }
  }, [searchParams]);

  return (
    <div
      className="charts-container"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: theme.palette.customColors.report.background,
      }}
    >
      {plots.map((p: any, i: number) => {
        return (
          <div className="chart" key={`chart _${i}`}>
            <Chart
              url={p.url}
              variant={ChartHelper.GetChartVariantFromUrl(p.url)}
              json_body={p.json_body}
              size={"sm"}
              useLoadingFrame={false}
            />
          </div>
        );
      })}
    </div>
  );
}
