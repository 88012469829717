import { Box } from "@mui/material";
import PlayerScoutingReport from "./scouting/ScoutingReport";
import { useEffect, useState, useRef } from "react";
import { api } from "../../api/twelve";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { ReportContent, ReportPage as ReportPageModel } from "./models";
import LoadingScreen from "./shared/LoadingScreen";

export default function ReportPage({
  isPublic = false,
}: {
  isPublic?: boolean;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState<boolean>(true);
  const [reportContent, setReportContent] = useState<ReportContent | undefined>(
    undefined,
  );
  const { reportId } = useParams();
  const abortControllerRef = useRef<AbortController | null>(null);
  const containerRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    const getReportPage = async (page: number) => {
      setLoading(true);

      try {
        // Add sceleton for the next page
        if (page > 0) {
          setReportContent((prev) => {
            if (prev?.content?.pages) {
              return {
                ...prev,
                content: {
                  ...prev.content,
                  pages: [
                    ...prev.content.pages,
                    {
                      index: page,
                      type:
                        page === prev?.content?.total_pages! - 1
                          ? "glossary_pages"
                          : "standard_page",
                      title: "Title",
                      subtitle: "Subtitle",
                      main_header: "Main header",
                      header:
                        page === 1
                          ? "Overview"
                          : prev.content?.table_of_content![page - 2],
                      subheader: prev.content?.pages![0].title,
                      slides: [
                        {
                          type: "standard_content",
                          header: "Header",
                          description: "Description",
                        },
                      ] as any,
                      loading: true,
                    } as ReportPageModel,
                  ].sort((a, b) => a.index - b.index),
                },
              };
            }
            return prev;
          });
        }

        // Fetch the next page
        let data: ReportContent;
        if (isPublic) {
          data = await api.getReportByPublicId(String(reportId), page, 1);
        } else {
          const token = await getAccessTokenSilently();
          if (!abortControllerRef.current) return;
          data = await api.getReportContentById(
            token,
            Number(reportId),
            abortControllerRef.current.signal,
            page,
            1,
          );
        }

        // Update the page with the fetched data
        setReportContent((prev) => {
          if (!prev) return data;
          if (prev?.content?.pages && data.content?.pages) {
            return {
              ...prev,
              content: {
                ...prev.content,
                pages: [
                  ...prev.content.pages?.filter((p) => p.index !== page),
                  ...data.content?.pages!,
                ].sort((a, b) => a.index - b.index),
              },
            };
          }
          return prev;
        });

        setLoading(false);
      } catch (error: any) {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      }
    };

    getReportPage(currentPage);
  }, [isPublic, reportId, getAccessTokenSilently, currentPage]);

  useEffect(() => {
    if (!isPublic) abortControllerRef.current = new AbortController();
    setReportContent(undefined);
    setCurrentPage(0);

    if (!isPublic) {
      return () => {
        if (abortControllerRef.current) abortControllerRef.current.abort();
      };
    }
  }, [isPublic, reportId]);

  const renderReport = () => {
    switch (reportContent?.report_type) {
      case "SCOUT_REPORT":
        return <PlayerScoutingReport {...reportContent} />;
      default:
        return <></>;
    }
  };

  const onScroll = async () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        // Fetch the next page if there are more pages
        if (currentPage < reportContent?.content?.total_pages! - 1) {
          setCurrentPage((prev) => prev + 1);
        }
      }
    }
  };

  return (
    <Box
      ref={containerRef}
      onScroll={onScroll}
      sx={{
        backgroundColor: (theme) =>
          theme.palette.customColors.report.background,
        overflow: "auto",
        height: "100%",
      }}
    >
      {loading && currentPage === 0 ? (
        <LoadingScreen useCounter={true} useTypingEffect={false} />
      ) : (
        renderReport()
      )}
    </Box>
  );
}
