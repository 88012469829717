export default function LoadingText({ length = 5 }: { length?: number }) {
  return (
    <>
      {length === 1 ? (
        <div
          className="loading-text"
          style={{ marginBottom: "20px", height: "22px" }}
        >
          Loading...
        </div>
      ) : (
        Array.from({ length }).map((_, i) => (
          <div
            key={`loading-text-${i}`}
            className="loading-text"
            style={{
              marginBottom: "10px",
              width: `${Math.random() * (100 - 70) + 70}%`,
              height: "10px",
            }}
          >
            Loading...
          </div>
        ))
      )}
    </>
  );
}
