import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  // IconButton,
  // InputAdornment,
  TextField,
  Typography,
  // useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  ReportOnTemplate,
  ReportTemplate,
  ReportTemplatePropertyType,
} from "../../models";
import TemplatePropertyIcon from "./TemplatePropertyIcon";
import { useSnackbar } from "../../../../shared/contexts/SnackbarContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import {
  addItem,
  updateItem,
  deleteItem,
} from "../../store/slices/reportListSlice";
import { api } from "../../../../api/twelve";
import SearchPlayerModal from "../../shared/search/SearchPlayerModal";
// import MicIcon from "@mui/icons-material/Mic";
import StringHelper from "../../../../shared/helpers/string.helper";
import { useCurrentUser } from "../../../auth/contexts/CurrentUserContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ResponsiveDropdownMenu, {
  ResponsiveDropdownMenuItemProps,
} from "../../../../shared/components/ResponsiveDropdownMenu";
import ResponsiveDialog from "../../../../shared/components/ResponsiveDialog";
import { UserPermissions } from "../../../auth/models";
import SearchTeamModal from "../../shared/search/SearchTeamModal";
import SearchMatchModal from "../../shared/search/SearchMatchModal";

export default function ReportForm({
  variant = "create",
}: {
  variant: "create" | "update";
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { currentUser } = useCurrentUser();
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuItems, setMenuItems] = useState<
    Array<ResponsiveDropdownMenuItemProps>
  >([]);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleOpenDeleteDialog = () => setDeleteDialogOpen(true);
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    setMenuAnchorEl(null);
  };

  const [template, setTemplate] = useState<ReportOnTemplate>({
    id: 0,
    template_id: 0,
    name: "",
    content: { sections: [], properties: [] },
  });

  const [errors, setErrors] = useState({
    name: false,
    properties: false,
    sections: [
      {
        questions: [false],
      },
    ],
  });

  useEffect(() => {
    if (!location.state?.report) return;

    const setReportOnTemplate = (template: ReportOnTemplate): void => {
      setErrors({
        name: false,
        properties: false,
        sections: template.content.sections.map(() => ({
          questions: template.content.sections.map(() => false),
        })),
      });
      setTemplate(structuredClone(template));
    };

    if (variant === "create") {
      const reportTemplate = location.state.report as ReportTemplate;
      const template = {
        id: 0,
        name: reportTemplate.name,
        template_id: reportTemplate.id,
        content: reportTemplate.structure,
      };
      setReportOnTemplate(template);
    } else {
      const fetchTemplate = async () => {
        const template = await api.getReportOnTemplateById(
          await getAccessTokenSilently(),
          location.state?.report.id,
          currentUser?.chekPermissions(
            UserPermissions.READ_ALL_SCOUT_REPORTS,
          ) && currentUser.id !== location.state?.report.user_id,
        );
        setReportOnTemplate(template);
      };
      fetchTemplate();
    }
  }, [variant, location, getAccessTokenSilently, currentUser]);

  useEffect(() => {
    if (variant === "create") {
      setCanUpdate(true);
      setMenuItems([]);
    } else {
      if (currentUser) {
        const items = [
          {
            label: "Duplicate",
            onClick: async () => {
              try {
                const report = { ...template };
                report.name = `${template?.name} (copy)`;
                report.template_name = template?.template_name;
                const duplicate = await api.addReportOnTemplate(
                  await getAccessTokenSilently(),
                  report,
                );
                duplicate.template_name = template?.template_name;
                dispatch(addItem(duplicate));
                navigate("/report");
                showSnackbar(
                  `Report ${template?.template_name} was duplicated`,
                );
              } catch (error: any) {
                showSnackbar(`${error.message}`, {}, "error");
              }
            },
          },
        ];
        if (currentUser.id === template.user_id) {
          items.push({ divider: true } as any);
          items.push({
            label: "Delete",
            onClick: () => {
              handleOpenDeleteDialog();
            },
            color: "warning.main",
          } as any);
          setCanUpdate(true);
          setMenuItems(items);
        } else {
          setCanUpdate(false);
          setMenuItems(items);
        }
      }
    }
  }, [
    template,
    currentUser,
    variant,
    dispatch,
    getAccessTokenSilently,
    navigate,
    showSnackbar,
  ]);

  const [open, setOpen] = useState<ReportTemplatePropertyType | undefined>(
    undefined,
  );

  const handleOpen = (type: ReportTemplatePropertyType | undefined) => {
    setOpen(type);
  };

  const handleClose = () => {
    setOpen(undefined);
  };

  const handleSelectProperty = (
    propertyType: ReportTemplatePropertyType,
    data: { id: number; value: string },
  ) => {
    const newTemplate = {
      ...template,
      content: {
        ...template.content,
        properties: template.content.properties.map((property) =>
          property.type === propertyType
            ? {
                ...property,
                data: data,
              }
            : property,
        ),
      },
    };
    setErrors((prevErrors) => ({
      ...prevErrors,
      properties: false,
    }));
    setTemplate(newTemplate);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      name: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      name: value.trim() === "",
    }));
  };

  const handleAnswerChange =
    (sectionIndex: number, questionIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setTemplate((prevTemplate) => {
        const newSections = prevTemplate.content.sections.map(
          (section, sIndex) => {
            if (sIndex === sectionIndex) {
              return {
                ...section,
                questions: section.questions.map((question, qIndex) => {
                  if (qIndex === questionIndex) {
                    return {
                      ...question,
                      answer: value,
                    };
                  }
                  return question;
                }),
              };
            }
            return section;
          },
        );

        return {
          ...prevTemplate,
          content: {
            ...prevTemplate.content,
            sections: newSections,
          },
        };
      });
      setErrors((prevErrors) => {
        const newSectionErrors = [...prevErrors.sections];
        newSectionErrors[sectionIndex].questions[questionIndex] =
          value.trim() === "";
        return {
          ...prevErrors,
          sections: newSectionErrors,
        };
      });
    };

  const handleSubmit = async () => {
    const nameError = template.name.trim() === "";
    const propertiesError = template.content.properties.some(
      (p) => !p.data?.value,
    );
    const sectionErrors = template.content.sections.map((section) => ({
      questions: section.questions.map(
        (subquestion) =>
          !subquestion.answer || subquestion.answer?.trim() === "",
      ),
    }));
    setErrors({
      name: nameError,
      properties: propertiesError,
      sections: sectionErrors,
    });

    if (
      !nameError &&
      !propertiesError &&
      !sectionErrors.some((section) => section.questions.some((error) => error))
    ) {
      try {
        if (variant === "create") {
          dispatch(
            addItem(
              await api.addReportOnTemplate(
                await getAccessTokenSilently(),
                template,
              ),
            ),
          );
        } else {
          dispatch(
            updateItem(
              await api.updateReportOnTemplate(
                await getAccessTokenSilently(),
                template,
              ),
            ),
          );
        }
      } catch (error: any) {
        showSnackbar(`${error.message}`, {}, "error");
        return;
      }
      showSnackbar(`Report ${template.name} was saved`);
      navigate("/report");
    } else {
      console.log("Validation errors:", errors);
    }
  };

  return (
    <Box
      className="scrollable"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
        padding: "16px",
        overflow: "auto",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          background: "transparent",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "100%",
          maxWidth: "640px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "16px",
            width: "100%",
            [theme.breakpoints.down("md")]: {
              pb: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "16px",
            }}
          >
            <Button
              variant="text"
              size="medium"
              color="primary"
              onClick={handleSubmit}
              disabled={!canUpdate}
            >
              Save
            </Button>
            <Button
              variant="text"
              size="medium"
              color="secondary"
              onClick={() => navigate("/report")}
            >
              Cancel
            </Button>
          </Box>
          {variant === "update" && (
            <>
              <IconButton onClick={handleMenuButtonClick}>
                <MoreVertIcon />
              </IconButton>
              <ResponsiveDropdownMenu
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                title={template.name}
                menuItems={menuItems}
              />
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: " 0 16px 16px 16px",
          }}
        >
          <Box>
            <TextField
              variant="standard"
              fullWidth
              required
              placeholder="Template name"
              value={template.name}
              onChange={handleNameChange}
              error={errors.name}
              helperText={errors.name ? "This field is required" : ""}
              InputLabelProps={{
                shrink: false,
              }}
              InputProps={{
                disableUnderline: true,
                sx: {
                  fontSize: "24px",
                  fontWeight: 700,
                  "& .MuiInputBase-root": {
                    borderBottom: "none",
                  },
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {template.content.properties.map((property, index) => (
                <Box
                  key={`property-${index}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      color: (theme) => theme.palette.text.secondary,
                      width: "200px",
                    }}
                  >
                    <Box>
                      <Box
                        className="icon"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "2px 4px 0 4px",
                          boxSizing: "border-box",
                        }}
                      >
                        <TemplatePropertyIcon property={property} />
                      </Box>
                    </Box>
                    <Typography variant="body1" sx={{ paddingTop: "2px" }}>
                      {StringHelper.normalizeKey(property.type)}
                    </Typography>
                  </Box>
                  <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    disabled={property.type === "tags" || !canUpdate}
                    onClick={() => handleOpen(property.type)}
                  >
                    {property.data?.value ? property.data.value : "Empty"}
                  </Button>
                </Box>
              ))}
            </Box>
            <SearchPlayerModal
              open={open === "player"}
              onClose={handleClose}
              onSelect={(player) =>
                handleSelectProperty("player", {
                  id: player.id,
                  value: player.name,
                })
              }
            />
            <SearchTeamModal
              open={open === "team"}
              onClose={handleClose}
              onSelect={(team) =>
                handleSelectProperty("team", {
                  id: team.team_id,
                  value: team.team,
                })
              }
            />
            <SearchMatchModal
              open={open === "match"}
              onClose={handleClose}
              onSelect={(match) => {
                handleSelectProperty("match", {
                  id: match.match_id,
                  value: match.label,
                });
              }}
            />
            {errors.properties && (
              <Typography color="error" sx={{ fontSize: "0.75rem" }}>
                Please select value for all properties
              </Typography>
            )}
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              {template.content.sections.map((section, sectionIndex) => (
                <Box
                  key={`section-${sectionIndex}`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    color: "text.secondary",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "text.secondary",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    {section.header}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    {section.questions.map((question, questionIndex) => (
                      <TextField
                        key={`subquestion-${questionIndex}`}
                        variant="standard"
                        fullWidth
                        required
                        placeholder="Answer..."
                        value={question.answer}
                        multiline
                        disabled={!canUpdate}
                        error={
                          errors.sections[sectionIndex].questions[questionIndex]
                        }
                        helperText={
                          errors.sections[sectionIndex].questions[questionIndex]
                            ? "This field is required"
                            : question.question
                        }
                        onChange={handleAnswerChange(
                          sectionIndex,
                          questionIndex,
                        )}
                        InputLabelProps={{
                          shrink: false,
                        }}
                        InputProps={{
                          sx: {
                            fontSize: "16px",
                            "&:hover .delete-icon": {
                              visibility: "visible",
                            },
                          },
                          // endAdornment: (
                          //   <InputAdornment
                          //     position="end"
                          //     sx={{
                          //       position: "absolute",
                          //       bottom: 20,
                          //       right: 10,
                          //     }}
                          //   >
                          //     <IconButton
                          //       edge="end"
                          //       className="delete-icon"
                          //       sx={{
                          //         visibility: isMobile ? "hidden" : "visible",
                          //       }}
                          //       onClick={() => {
                          //         template.content.sections[
                          //           sectionIndex
                          //         ].questions.splice(questionIndex, 1);
                          //         setTemplate({
                          //           ...template,
                          //         });
                          //       }}
                          //     >
                          //       <MicIcon fontSize="small" />
                          //     </IconButton>
                          //   </InputAdornment>
                          // ),
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Paper>
      <ResponsiveDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        title="Delete report"
        actions={
          <>
            <Button
              variant="text"
              size="medium"
              color="secondary"
              onClick={() => {
                handleCloseDeleteDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="warning"
              onClick={async () => {
                if (!template.id) return;
                try {
                  await api.deleteReportOnTemplate(
                    await getAccessTokenSilently(),
                    template.id,
                  );

                  dispatch(deleteItem(template.id));
                  navigate("/report");
                  showSnackbar(
                    `Report ${template.name} was deleted`,
                    { icon: <></>, textColor: theme.palette.error.main },
                    "warning",
                  );
                } catch (error: any) {
                  showSnackbar(`${error.message}`, {}, "error");
                }
                handleCloseDeleteDialog();
              }}
            >
              Delete
            </Button>
          </>
        }
      >
        <Typography variant="body1" color={"text.secondary"}>
          This action can’t be undone.
        </Typography>
      </ResponsiveDialog>
    </Box>
  );
}
