import { Box, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../shared/icons/twelve-logo.svg";
import InputArea from "./chat/InputArea";
import { useCallback } from "react";
import { config } from "../../config";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addConversation } from "../../store/conversationsReducer";
import { api } from "../../api/twelve";

export default function ConversationStartingScreen() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCreate = useCallback(
    (message: string) => {
      const fn = async () => {
        const conversationName = config.conversationNaming.default;
        const { id: conversationId } = await dispatch(
          await addConversation(
            await getAccessTokenSilently(),
            conversationName,
          ),
        );
        const chat = await api.createChat(
          await getAccessTokenSilently(),
          conversationId,
          conversationName,
          "RECEPTIONIST",
          {
            version: "1",
          },
        );
        navigate(`/conversation/${conversationId}`, {
          state: { firstMessage: message, chatId: chat.id },
          replace: true,
        });
      };
      fn();
    },
    [getAccessTokenSilently, navigate, dispatch],
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "2px",
          width: "100%",
        }}
      >
        <Logo style={{ width: "32px", height: "32px" }} />
        <Typography
          variant="caption"
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            color: "text.primary",
          }}
        >
          Welcome to TwelveGPT!
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            color: "text.secondary",
            padding: "0 32px",
            textAlign: "center",
            letterSpacing: "0.15px",
            lineGeight: "125%",
          }}
        >
          To get started, simply provide the name of a player.
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "32px 16px 0 16px",
          }}
        >
          <InputArea
            onSend={(message) => handleCreate(message)}
            loading={false}
            disabled={false}
          />
        </Box>
      </Box>
    </Box>
  );
}
