import { Box, Typography, useMediaQuery, useTheme, alpha } from "@mui/material";
import { ReportPage, StandardContentReportSlide } from "../../models";
import SwipeableContent from "../../../../shared/components/SwipeableContent";
import {
  Page,
  PageBox,
  PageBoxContent,
  PageBoxHeader,
  PageContent,
  PageHeader,
} from "../../shared/ReportLayout";
import MarkdownText from "../../../../shared/components/MarkdownText";
import { Chart } from "../../../../shared/components/charts/Chart";
import ChartHelper from "../../../../shared/helpers/chart.helper";
import { darkModeApplicationPalette } from "../../../../themes/palettes";
import { useEffect, useState } from "react";
import { api } from "../../../../api/twelve";
import LoadingText from "../../../../shared/components/LoadingText";
import { BaseContext } from "../../../../models";

export default function StandardPage({
  page,
  pageNumber,
}: {
  page: ReportPage;
  pageNumber: number;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));

  const [descriptions, setDescriptions] = useState<
    Array<string | undefined> | undefined
  >([]);

  const [titles, setTitles] = useState<Array<string | undefined> | undefined>(
    [],
  );

  const [pageTitle, setPageTitle] = useState<string | undefined>(undefined);
  const [context, setContext] = useState<BaseContext | undefined>(undefined);

  useEffect(() => {
    setDescriptions(page.slides?.map(() => undefined));
    setTitles(page.slides?.map(() => undefined));

    const getDescriptions = async () => {
      if (page.slides) {
        page.slides.forEach(
          async (slide: StandardContentReportSlide, index: number) => {
            if (slide.description_specs) {
              const description = await api.getDescription(
                slide.description_specs.url,
                slide.description_specs.json_body,
              );
              setDescriptions((prevDescriptions) => {
                if (prevDescriptions) {
                  prevDescriptions[index] = description;
                  return [...prevDescriptions];
                }
                return prevDescriptions;
              });

              //
              const title = await api.getTitle(
                [description],
                slide.description_specs.json_body,
              );
              setTitles((prevTitles) => {
                if (prevTitles) {
                  prevTitles[index] = title;
                  return [...prevTitles];
                }
                return prevTitles;
              });
            }
          },
        );
      }
    };

    if (page.slides) {
      setContext(
        (page.slides[0] as StandardContentReportSlide)?.description_specs
          ?.json_body as BaseContext,
      );
    }

    getDescriptions();
  }, [page]);

  useEffect(() => {
    const getPageTitle = async (descriptions: Array<string | undefined>) => {
      if (context) {
        const title = await api.getTitle(descriptions, context);
        setPageTitle(title);
      }
    };

    if (descriptions) {
      const allDescriptions = descriptions.filter(
        (description) => description !== undefined,
      );
      if (
        allDescriptions.length > 0 &&
        allDescriptions.length === descriptions.length
      ) {
        getPageTitle(allDescriptions);
      }
    }
  }, [descriptions, context]);

  return (
    <Page id={`page-${pageNumber}`} key={`page-${pageNumber}`}>
      <PageHeader
        title={page.header}
        subtitle={!isMobile ? page.subheader : undefined}
      />
      {!isMobile ? (
        <Box sx={{ position: "relative" }}>
          <Typography
            variant="h4"
            className={!pageTitle ? "loading-text" : ""}
            sx={{
              fontWeight: "34px",
              height: `${page.loading ? "34px" : "unset"}`,
              color: alpha(
                theme.palette.customColors.text,
                theme.palette.customColors.opacity.text.primary,
              ),
              marginBottom: "20px",
            }}
          >
            {!pageTitle ? "Loading" : pageTitle}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
      <PageContent sx={{ justifyContent: "center" }}>
        <Box
          sx={{
            height: "100%",
            [theme.breakpoints.up("xl")]: {
              maxHeight: "620px",
            },
          }}
        >
          <SwipeableContent
            allowExpand={false}
            indicatorsVariant={isMobile ? "circle" : "button"}
            indicatorsStyle={{
              activeColor: darkModeApplicationPalette.action?.active,
              disabledColor: darkModeApplicationPalette.text?.disabled,
            }}
            descriptions={isMobile ? descriptions : undefined}
            useLoadingText={true}
          >
            {page.slides
              ?.sort(
                (
                  a: StandardContentReportSlide,
                  b: StandardContentReportSlide,
                ) =>
                  ChartHelper.ORDER[
                    ChartHelper.GetChartVariantFromUrl(a.plot?.url)
                  ] -
                  ChartHelper.ORDER[
                    ChartHelper.GetChartVariantFromUrl(b.plot?.url)
                  ],
              )
              .map((slide: StandardContentReportSlide, slideIndex) => (
                <Box
                  key={`page-${pageNumber}-slide-${slideIndex}`}
                  sx={{
                    display: "flex",
                    gap: "12px",
                    height: "100%",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column-reverse",
                    },
                  }}
                >
                  {!isMobile && (
                    <PageBox className="scrollable" sx={{ flexGrow: 1 }}>
                      {titles![slideIndex] ? (
                        <PageBoxHeader title={titles![slideIndex]} />
                      ) : (
                        <Box sx={{ padding: "0 24px" }}>
                          <LoadingText length={1} />
                        </Box>
                      )}
                      <PageBoxContent sx={{ overflow: "auto" }}>
                        <MarkdownText
                          text={descriptions![slideIndex]}
                          useLoadingText={true}
                        />
                      </PageBoxContent>
                    </PageBox>
                  )}
                  <Box>
                    <Chart
                      sx={{ height: isSm ? "600px" : "100%" }}
                      variant={ChartHelper.GetChartVariantFromUrl(
                        slide.plot?.url,
                      )}
                      url={slide.plot?.url}
                      json_body={slide.plot?.json_body}
                      useLoadingFrame={true}
                      loadingFrameVariant="shimmer"
                    />
                  </Box>
                </Box>
              ))}
          </SwipeableContent>
        </Box>
      </PageContent>
    </Page>
  );
}
