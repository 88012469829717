import React, { useEffect, useState } from "react";
import { Box, useTheme, alpha, Typography, styled } from "@mui/material";
import TypingEffect from "../../../shared/components/TypingEffect";

const RotatingSVG = styled("svg")({
  animation: "rotate 50s linear infinite",
  transformOrigin: "center center",
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

export default function LoadingScreen({
  useTypingEffect = true,
  useCounter = false,
}: {
  useTypingEffect?: boolean;
  useCounter?: boolean;
}) {
  const getRandomInt = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const theme = useTheme();
  const [visible, setVisible] = useState<boolean>(false);

  const centerTextColor = theme.palette.customColors.text;
  const bottomTextColor = alpha(theme.palette.customColors.text, 0.4);
  const counterTextColor = "rgba(255, 255, 255, 0.38)";

  useEffect(() => {
    setTimeout(() => setVisible(true), 50);
  }, []);

  const [count, setCount] = useState(getRandomInt(40000, 90000));

  useEffect(() => {
    const maxCount = Number.POSITIVE_INFINITY;

    if (!useCounter || count >= maxCount) return;

    const randomIncrement = getRandomInt(1, 9);

    const timeout = setTimeout(() => {
      setCount((prev) => Math.min(prev + randomIncrement, maxCount));
    }, 1);

    return () => clearTimeout(timeout);
  }, [count, useCounter]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        top: "0",
        left: "0",
        overflow: "hidden",
        display: `${visible ? "block" : "none"}`,
        borderRadius: `${useTypingEffect ? "0px" : "8px"}`,
      }}
    >
      {/* Background */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: theme.palette.customColors.report.background,
        }}
      >
        <RotatingSVG
          width="150%"
          height="150%"
          viewBox="0 0 1489 982"
          fill="none"
        >
          <g opacity="0.5">
            <path
              d="M-148.272 676.322L-184.616 776.891C-197.195 811.698 -158.677 842.765 -127.322 823.102C-118.002 817.257 -106.645 815.629 -96.0582 818.619L306.093 932.208C318.104 935.6 330.557 937.17 343.034 936.864L632.18 929.768C743.3 927.041 795.552 791.241 714.92 714.733L597.348 603.176C571.125 578.294 535.392 566.022 499.415 569.541L-84.3732 626.651C-113.47 629.498 -138.336 648.826 -148.272 676.322Z"
              fill="#3A3A3A"
            />
            <path
              d="M45.7279 350.322L9.38367 450.891C-3.19523 485.698 35.3227 516.765 66.6777 497.102C75.9975 491.257 87.3551 489.629 97.9418 492.619L500.093 606.208C512.104 609.6 524.557 611.17 537.034 610.864L826.18 603.768C937.3 601.041 989.552 465.241 908.92 388.733L791.348 277.176C765.125 252.294 729.392 240.022 693.415 243.541L109.627 300.651C80.5297 303.498 55.6644 322.826 45.7279 350.322Z"
              fill="#FF4B00"
              fillOpacity="0.2"
            />
            <path
              d="M293.728 -8.67834L256.147 95.3134C243.973 128.999 281.25 159.065 311.594 140.035C322.616 133.124 336.423 132.387 348.117 138.087L999.64 455.65C1043.8 477.175 1096.49 470.752 1134.19 439.247L1268.37 327.111C1325.56 279.314 1328.3 192.344 1274.23 141.042L1039.35 -81.8242C1013.12 -106.706 977.392 -118.978 941.415 -115.459L357.627 -58.3489C328.53 -55.5025 303.664 -36.1739 293.728 -8.67834Z"
              fill="#009940"
            />
            <path
              d="M753.241 798.339L719.916 890.555C701.497 941.523 718.12 998.552 761.04 1031.64L891.498 1132.21C950.364 1177.59 1035.61 1161.7 1074.17 1098.16L1191.26 905.191C1220.16 857.566 1284.68 846.843 1327.43 882.56C1388.57 933.644 1481.2 887.07 1476.65 807.525L1474.45 769.005C1470.97 707.893 1417.33 661.963 1356.41 667.923L858.335 716.647C810.479 721.329 769.584 753.118 753.241 798.339Z"
              fill="#002911"
            />
            <path
              d="M846.543 295.94L828.643 374.416C825.466 388.346 842.468 397.776 852.603 387.705C856.787 383.548 863.098 382.395 868.482 384.804L1000.76 444.002C1016.88 451.217 1034.45 454.614 1052.1 453.928L1066.23 453.378C1149.57 450.137 1197.22 356.91 1151.03 287.465C1127.97 252.796 1086.87 234.684 1045.72 241.062L877.44 267.146C862.214 269.506 849.969 280.917 846.543 295.94Z"
              fill="white"
            />
            <path
              d="M28.8394 124.338L10.9403 202.815C7.76331 216.744 24.7648 226.174 34.8994 216.104C39.0835 211.946 45.3951 210.793 50.779 213.203L183.054 272.401C199.176 279.616 216.745 283.012 234.395 282.326L248.531 281.776C331.872 278.536 379.52 185.308 333.329 115.864C310.268 81.1942 269.165 63.0829 228.017 69.4608L59.7371 95.5444C44.5109 97.9045 32.2657 109.316 28.8394 124.338Z"
              fill="white"
            />
          </g>
        </RotatingSVG>
      </Box>
      {/* Blur effect */}
      <Box
        sx={{
          top: 0,
          left: 0,
          position: "absolute",
          width: "100%",
          height: "100%",
          backdropFilter: "blur(124.64964294433594px)",
        }}
      />
      {/* Text with typing effect */}
      {useTypingEffect && (
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            padding: "0 24px",
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TypingEffect
            messages={[
              "Analyzing their defensive actions—every tackle and interception under the microscope…",
              "Evaluating their aerial dominance—do they win the battles in the air?",
              "Assessing their threat inside the box—can they change the game when it matters most?",
              "Studying how they prevent opponents’ chances with smart positioning…",
              "Measuring their composure under pressure—calm and collected or rushed decisions?",
              "Examining their heading ability—both clearing danger and scoring goals…",
              "Breaking down their dribbling skills—can they beat defenders one-on-one?",
              "Calculating their finishing prowess—turning opportunities into goals…",
              "Assessing their hold-up play—do they effectively bring teammates into the game?",
              "Measuring their influence—how involved are they in key moments throughout the match?",
            ].sort(() => Math.random() - 0.5)}
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              color: centerTextColor,
            }}
            showCursor={false}
            clearingSpeed={0}
            pauseTime={2000}
            variant="fixed-sizing"
          />
        </Box>
      )}
      {/* Counter */}
      {useCounter && (
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2px",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.customColors.teamColors.main,
              fontSize: "48px",
              fontWeight: 400,
              fontVariantNumeric: "tabular-nums",
              background: `linear-gradient(
                180deg,
                ${theme.palette.customColors.teamColors.main} 0%,
                ${alpha(theme.palette.customColors.teamColors.main, 0.3)} 63.62%
              )`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
              transition: "all 0.3s ease-in-out",
            }}
          >
            {count}
          </Typography>
          <Typography
            sx={{
              color: counterTextColor,
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            Analyzed actions
          </Typography>
        </Box>
      )}
      {/* Footer */}
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          bottom: "40px",
          transform: "translateX(-50%)",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {!useCounter && (
          <Typography
            sx={{
              color: bottomTextColor,
              fontSize: `${useTypingEffect ? "18px" : "16px"}`,
              fontWeight: 400,
              textWrap: "nowrap",
            }}
          >
            Getting the data
          </Typography>
        )}
        <svg width="42px" viewBox="0 0 52 24">
          <circle fill={bottomTextColor} stroke="none" cx="6" cy="12" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle fill={bottomTextColor} stroke="none" cx="26" cy="12" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle fill={bottomTextColor} stroke="none" cx="46" cy="12" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </svg>
      </Box>
    </Box>
  );
}
