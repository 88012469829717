export default class PlayerPositionHelper {
  public static getPosition = (
    abbreviation: string,
  ): { name: string; point: { x: number; y: number } } => {
    switch (abbreviation) {
      case "CF":
        return {
          name: "Striker",
          point: { x: 49, y: 49 },
        };
      case "SS":
        return {
          name: "Second Striker",
          point: { x: 49, y: 49 },
        };
      case "LB":
      case "LB5":
        return {
          name: "Left Back",
          point: { x: 20.5, y: 90 },
        };
      case "LWB":
        return {
          name: "Left Wing Back",
          point: { x: 20.5, y: 90 },
        };
      case "LW":
      case "LAMF":
        return {
          name: "Left Winger",
          point: { x: 35, y: 90 },
        };
      case "LAM":
      case "LWF":
        return {
          name: "Left Forward",
          point: { x: 49, y: 90 },
        };
      case "AM":
      case "AMF":
        return {
          name: "Attacking Midfielder",
          point: { x: 42, y: 49 },
        };
      case "CM":
      case "LCMF":
      case "RCMF":
      case "LCMF3":
      case "RCMF3":
        return {
          name: "Central Midfielder",
          point: { x: 35, y: 49 },
        };
      case "DM":
      case "DMF":
      case "LDMF":
      case "RDMF":
        return {
          name: "Defensive Midfielder",
          point: { x: 28, y: 49 },
        };
      case "RB":
      case "RB5":
        return {
          name: "Right Back",
          point: { x: 20.5, y: 7 },
        };
      case "RWB":
        return {
          name: "Right Wing Back",
          point: { x: 20.5, y: 7 },
        };
      case "RW":
      case "RAMF":
        return {
          name: "Right Winger",
          point: { x: 35, y: 7 },
        };
      case "RAM":
      case "RWF":
        return {
          name: "Right Forward",
          point: { x: 49, y: 7 },
        };
      case "CB":
      case "LCB":
      case "RCB":
      case "LCB3":
      case "RCB3":
        return {
          name: "Central Defender",
          point: { x: 20.5, y: 49 },
        };
      default:
        return {
          name: "",
          point: { x: -100, y: -100 },
        };
    }
  };
}
